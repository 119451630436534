<template>
  <v-row v-if="item">
    <v-col cols="12" sm="6">
      <vx-card-list title="Basic info" :item="item">
        <v-list-item>
          <v-list-item-title> Id </v-list-item-title>
          <v-list-item-subtitle class="cursor-pointer info--text" @click="$copy(item._id)">
            {{ item._id }}
          </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Name </v-list-item-title>
          <v-list-item-subtitle> {{ item.name }} </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Slug </v-list-item-title>
          <v-list-item-subtitle>{{ item.slug }} </v-list-item-subtitle>
        </v-list-item>

        <!-- <v-list-item :to="$toView('feature', item.featureId._id)">
          <v-list-item-title> Feature </v-list-item-title>
          <v-list-item-subtitle> {{ item.featureId?.name }} </v-list-item-subtitle>
        </v-list-item> -->

        <v-list-item>
          <v-list-item-title> Tooltip </v-list-item-title>
          <v-list-item-subtitle class="white-space-normal">{{ item.tooltip }} </v-list-item-subtitle>
        </v-list-item>

        <v-list-item>
          <v-list-item-title> Display Sequence </v-list-item-title>
          <v-list-item-subtitle> {{ item.sort }} </v-list-item-subtitle>
        </v-list-item>
      </vx-card-list>
    </v-col>

    <v-col cols="12" sm="6">
      <vx-card-list title="References" hide-edit>
        <v-list-item :to="$toList('location', { attributeId: item._id })">
          <v-list-item-title> Locations </v-list-item-title>
          <v-list-item-subtitle>{{ item.count?.locations }} </v-list-item-subtitle>
        </v-list-item>

        <v-list-item :to="$toList('space', { attributeId: item._id })">
          <v-list-item-title> Spaces </v-list-item-title>
          <v-list-item-subtitle>{{ item.count?.spaces }} </v-list-item-subtitle>
        </v-list-item>
      </vx-card-list>
    </v-col>

    <v-col cols="12">
      <vx-card-json :item="item" />
    </v-col>
  </v-row>
</template>

<script>
import { AttributeService } from '@tutti/services';

export default {
  data() {
    return {
      item: null,
      count: {},
    };
  },
  created() {
    this.getAttribbute();
  },
  methods: {
    async getAttribbute() {
      const response = await AttributeService.getById(this.$route.params.id);
      if (response) {
        this.item = response.data;
        this.$setTitle(this.item.name);
      }
    },
  },
};
</script>
