var render = function () {
  var _vm$item$count, _vm$item$count2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "Basic info",
      "item": _vm.item
    }
  }, [_c('v-list-item', [_c('v-list-item-title', [_vm._v(" Id ")]), _c('v-list-item-subtitle', {
    staticClass: "cursor-pointer info--text",
    on: {
      "click": function click($event) {
        return _vm.$copy(_vm.item._id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item._id) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Name ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.name) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Slug ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.item.slug) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Tooltip ")]), _c('v-list-item-subtitle', {
    staticClass: "white-space-normal"
  }, [_vm._v(_vm._s(_vm.item.tooltip) + " ")])], 1), _c('v-list-item', [_c('v-list-item-title', [_vm._v(" Display Sequence ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.item.sort) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-card-list', {
    attrs: {
      "title": "References",
      "hide-edit": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "to": _vm.$toList('location', {
        attributeId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Locations ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$item$count = _vm.item.count) === null || _vm$item$count === void 0 ? void 0 : _vm$item$count.locations) + " ")])], 1), _c('v-list-item', {
    attrs: {
      "to": _vm.$toList('space', {
        attributeId: _vm.item._id
      })
    }
  }, [_c('v-list-item-title', [_vm._v(" Spaces ")]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$item$count2 = _vm.item.count) === null || _vm$item$count2 === void 0 ? void 0 : _vm$item$count2.spaces) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-card-json', {
    attrs: {
      "item": _vm.item
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }